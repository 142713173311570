<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center mb-4">
        <h2 class="font-weight-bold black--text">
          {{ $t("digitizeWorkFocusTimeGrowingBusiness") }}
        </h2>
        <v-container>
            <h4 class="green-prosk">{{ $t("subscribeAndGrowYourBusiness") }}</h4>
        </v-container>
      </v-col>
      <v-col cols="12">
        <div :class="$vuetify.breakpoint.mobile ? 'container' : ''">
        <v-card elevation="5" class="bg-prosk-secondary white--text rounded-xl">
          <v-row>
            <v-col
                md="4"
                cols="12"
                class="p-md-4 px-3"
                v-for="(item, i) in items"
                :key="i"
                :class="
              $vuetify.breakpoint.mobile ? 'border-bottom' : 'borderHowWorks d-flex align-items-center'
            "
            >
              <v-row class="d-flex align-items-center container">
                <v-col cols="2">
                  <h1 class="green-prosk"> {{ item.step }} </h1>
                </v-col>
                <v-col cols="10">
                  <span class="lead">{{ $t(item.text) }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </div>
      </v-col>
      <v-row align="center" justify="center" class="my-5">
          <v-col cols="12" class="text-center">
              <v-btn :to="{ name: 'auth1.sign-up1' }" color="#59D79D" rounded>
                  <span class="secondary-prosk font-weight-bold">{{ $t('createYourProskerProfile') }}</span>
              </v-btn>
          </v-col>
      </v-row>
      <v-col cols="12" class="text-center mb-4">
        <h2 class="text-prosk font-weight-bold">{{ $t("faqs") }}</h2>
      </v-col>
      <v-expansion-panels accordion flat class="mb-6" focusable v-model="activeTab">
        <v-expansion-panel v-for="(item, i) in faqs" :key="i" class="bg-grey">
          <v-expansion-panel-header
              class="font-weight-bold"
              :class="{'bg-green-prosk': i === activeTab}"
          >
            <template v-slot:actions>
              <v-icon>
                {{  i === activeTab ? 'mdi-minus' : 'mdi-plus' }}
              </v-icon>
            </template>
            {{ $t(item.question) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-5">
            {{ $t(item.answer) }}
            <router-link v-show="item.link" :to="{ name: item.link }"> {{ $t("signUp") }}</router-link>
            <span v-show="item.answer2">{{$t(item.answer2)}}</span>
            <span v-show="item.answer3" class="font-weight-bold"> {{$t(item.answer3)}} </span>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'HowWorksProsker',
  data () {
    return {
      items: [
        {
          step: 1,
          text: 'createYourProskerProfile'
        },
        {
          step: 2,
          text: 'showWhatYouDo'
        },
        {
          step: 3,
          text: 'communicateWithClients'
        }
      ],
      activeTab: null,
      subscriptionInformation: {},
      faqs: [
        {
          question: 'whatIsPROSK',
          answer: 'whatIsPROSKAnswer'
        },
        {
          question: 'howDoIRegisterInPROSK',
          answer: 'createYourUsernamePasswordCompletingForm',
          link: 'auth1.sign-up1',
          answer2: 'thenCompleteYouProfile'
        },
        {
          question: 'isMyPROSKProfileUnique',
          answer: 'yourPROSKProfileIsUnique',
          answer2: 'whereSelectAllCategories'
        },
        {
          question: 'howCanIHaveGoodReputation',
          answer: 'TrustIsEssentialDependsOnQuality'
        },
        {
          question: 'howCanIMakeAttractiveProfile',
          answer: 'takingCareOfImage',
          answer2: 'takingCareOfImage2'
        },
        {
          question: 'howDoUsersContactMe',
          answer: 'usersRegisteredInPROSKWillSendYouMessage'
        },
        {
          question: 'howDoesPROSKNotifyMe',
          answer: 'currentlyFromProfileWillHaveNotifications'
        },
        {
          question: 'canIChargeServices',
          answer: 'soonPROSKWillHaveServiceAcceptPayments'
        },
        {
          question: 'whatDoINeedToCollectServices',
          answer: 'whatDoINeedToCollectServicesAnswer'
        },
        {
          question: 'canISchedule',
          answer: 'soonPROSKWillHaveDigitalAgenda',
          answer2: 'soonPROSKWillHaveDigitalAgenda2'
        },
        {
          question: 'doesPROSKChargeCommission',
          answer: 'noThatDependsHowYouUsePlatform'
        },
        {
          question: 'doIHaveToPaySubscription',
          answer: 'itDependsOnYou',
          answer2: 'itDependsOnYou2',
          answer3: 'itDependsOnYou3'
        },
        { question: 'isThereTrialPeriod', answer: 'yesInAllCasesOffer30Days' },
        {
          question: 'howDoIReceivePayment',
          answer: 'yourMercadoPagoAccount'
        },
        {
          question: 'howLongShouldIWaitCollectSales',
          answer: 'chargeDelay'
        },
        {
          question: 'doIHaveToIssueInvoices',
          answer: 'serviceYouProvideYouAreResponsible'
        },
        {
          question: 'ifIGetJobThroughAmICovered',
          answer: 'youAreResponsibleComplyingObligations'
        },
        {
          question: 'dataProtected',
          answer: 'dataProtectedAnswer',
          answer2: 'dataProtectedAnswer2'
        }
      ],
      subscriptionType: {},
      subscriptionPlans: []
    };
  },
  created () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    this.getSubscriptionPlans();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    sendEmailOrLogin () {
      if (!this.currentUser) {
        this.$router.push({ name: 'auth1.sign-in1' });
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/api/subscriptions/contact`;
        const data = {
          user: this.currentUser
        };
        axios.post(url, data).then(() => {
          this.$swal.fire({
            title: this.$t('awesome'),
            text: this.$t('queryReceived'),
            confirmButtonColor: '#19AD93'
          });
        });
      }
    },
    sendToRegisterFreePlan () {
      this.subscriptionType.free = true;
      this.$router.push({
        name: 'auth1.sign-up1',
        params: { query: JSON.stringify(this.subscriptionType) }
      });
    },
    sendToCheckoutPremiumPlan (subscription) {
      this.subscriptionInformation = subscription;
      this.subscriptionInformation.isSubscriptionType = true;
      this.subscriptionInformation.isUserLogged = true;
      this.$router.push({
        name: 'checkout',
        params: { query: JSON.stringify(this.subscriptionInformation) }
      });
    },
    sendToCheckoutNotLoggedPremiumPlan (subscription) {
      this.subscriptionInformation = subscription;
      this.subscriptionInformation.isSubscriptionType = true;
      this.subscriptionInformation.isUserLogged = false;
      this.$router.push({
        name: 'checkout',
        params: { query: JSON.stringify(this.subscriptionInformation) }
      });
    },
    beginOnProsk (subscription) {
      if (!this.currentUser && subscription.id === 1) {
        this.sendToRegisterFreePlan();
      } else if (this.currentUser && subscription.id === 2) {
        this.sendToCheckoutPremiumPlan(subscription);
      } else if (!this.currentUser && subscription.id === 2) {
        this.sendToCheckoutNotLoggedPremiumPlan(subscription);
      }
    },
    getSubscriptionPlans () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/subscriptions`;
      this.subscriptionPlans.splice(0, this.subscriptionPlans.length);
      axios.get(url).then((response) => {
        response.data.data.forEach((category) => {
          this.subscriptionPlans.push(category);
        });
      });
    }
  }
};
</script>
